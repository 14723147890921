<template>
    <v-container class="w-full mx-auto py-5 py-md-16 pa-6 mt-1 ">
        <v-row class="bg-contact rounded-xl d-flex flex-md-row flex-column">
            <v-col md="6" class="px-5 pt-5 pt-md-12 pa-md-12 pb-0 content-image heading">
                <h1 class="my-1 mt-md-4 font-bold text-h5 font-weight-black text-md-h4">{{ $t("contact.title") }}</h1>
                <p class="text-subtitle-2">{{ $t("contact.subtitle") }}</p>
                <img src="../assets/illustrations/contactUs.svg" srcset="" class="" >
            </v-col>
            <v-col md="6" justify="end" class="pa-sm-12 pt-0 pb-5 py-sm-5 py-md-12 px-6 my-auto">
                <v-form class="pa-5 rounded-xl card" ref="form">
                    <v-card-text class="px-2 px-sm-4">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-select filled shaped
                                    :label="$t('contact.subjectForm')" :items="subject" item-value="id" :item-text="lang === 'es' ? 'name' : 'name_en'"
                                    v-model="contactForm.subject_id" color="#8a72c1"  :rules="[rules.required]" :loading="loadingSubject"  :disabled="loadingSubject" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field filled shaped :label="$t('contact.nameForm')" v-model="contactForm.first_name" color="#8a72c1"
                                    :rules="[rules.required, rules.min]" />
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-text-field filled shaped :label="$t('contact.lastNameForm')" v-model="contactForm.last_name"
                                    color="#8a72c1" :rules="[rules.required, rules.min]" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-text-field filled shaped :label="$t('contact.emailForm')" type="email"  id="email" v-model="contactForm.email"
                                    color="#8a72c1" :rules="[rules.required, rules.email]" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-textarea filled shaped :label="$t('contact.messageForm')" v-model="contactForm.message"
                                    color="#8a72c1" :rules="[rules.required, rules.minMessage]" :maxlength="600" counter
                                    rows="3" hint="Máximo 600 caracteres" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="#8a72c1" :loading="loading"  :disabled="!isFormValid" class="btn-send" @click="registercontactForm">{{
                            $t("contact.btn-contactForm") }}</v-btn>
                    </v-card-actions>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from '@/axios.js';
export default {
    data() {
        return {
            contactForm: {
                subject_id: "",
                first_name: "",
                last_name: "",
                email: "",
                message: "",
            },
            snackbar: {
                show: false,
                message: "",
                type: ""
            },
            loadingSubject: false,
            subject: [],
            loading: false,
            rules: {
                required: value => !!value || this.$t('contact.required'),
                min: value => (value && value.length >= 3) || this.$t('contact.min', { min: 3 }),
                minMessage: value => (value && value.length >= 20) || this.$t('contact.minMessage', { min: 20 }),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail.';
                },
            },
        };
    },
    created() {
        this.checkLanguage();
    },
    mounted() {
        this.getSubjects();
    },
    methods: {
        checkLanguage() {
            const storedLocale = localStorage.getItem("locale");
            this.lang = storedLocale;
        },
        async registercontactForm() {
            this.loading = true;
            try {
                const response = await axios.post('send-contact', this.contactForm);
                console.log(response.data);
                if (response.data.success) {
                    this.$refs.form.reset();
                    this.snackbar.message = this.$t('form.successMessage');
                    this.snackbar.type = 'success';
                    this.snackbar.show = true;
                    this.$vs.notify({
                        title: this.$t('contact.messageForm'),
                        text: this.$t('contact.message-success'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                } else {
                    this.$vs.notify({
                        title: this.$t('contact.messageForm'),
                        text: this.$t('contact.message-danger'),
                        color: 'danger',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });
                }
            } catch (error) {
                console.error(error);
                this.$vs.notify({
                    title: this.$t('contact.messageForm'),
                    text: this.$t('contact.message-danger'),
                    color: 'danger',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });
            } finally {
                this.loading = false;
            }
        },
        async getSubjects(){
            this.loadingSubject = true;
            const response = await axios.get('get-contact-options');
            this.subject = response.data.subjects;
            this.loadingSubject = false;
        },


    },
    computed: {
        isFormValid() {
            return (
                this.rules.required(this.contactForm.first_name) === true &&
                this.rules.required(this.contactForm.subject_id) === true &&
                this.rules.required(this.contactForm.message) === true &&
                this.rules.required(this.contactForm.email) === true &&
                this.rules.email(this.contactForm.email) === true &&
                this.rules.min(this.contactForm.first_name) === true &&
                this.rules.minMessage(this.contactForm.message) === true
            );
        },
        lang() {
            return this.$i18n.locale;
        }
    },
};
</script>

<style scoped>
.card {
    background: white;
    gap: 20px;
    color: rgb(146, 101, 230);
    -webkit-box-shadow: 0px 3px 16px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 16px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 16px 1px rgba(0, 0, 0, 0.15);
}

.btn-send {
    color: white;
}

.bg-contact {
    background-color: #978ace2e;

}

.content-image {
    margin:auto;
    height: 100%;
}

.content-image img {
    max-height: 400px;
}

.heading h1 {
    color: #444444;
}

.heading p {
    color: #646464;
    margin-bottom: 0;
}

@media (max-width: 600px) {
    .content-image img {
        max-height: 280px;
    }
}
</style>
